import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
// import Clients from "./Clients";

function OurTestimonialsSection(props) {
  return (
    <Section className="testimonial-section py-100" id="testimonials">
        <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-header mb-60 text-center">
                            <p class="section-highlight">testimonial</p>
                            <h2 class="wow animated fadeInUp slow">What Our Clients Say</h2>
                        </div>
                    </div>
                </div>
                <div class="testimonial-wrap" data-wow-delay="0.60s">
                    <div class="testimonial-single-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="testimonial-image-wrapper">
                                    <div class="tastimonial-image">
                                        <img src={require('../assets/images/testimonial/testimonial-1.jpg')} alt="bright-side-security-private-security-officers-in-fremont-california" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="testimonial-slider-wrapper">
                                    <div class="testimonial-slid-box">
                                        <p class="mb-25"><span class="flaticon-quote-1 d-block quote mb-25"></span>
                                        Bright Side Security is the best security service in the whole town as they stick to words that they commit. They are just one phone call away. I highly recommend them.<span class="flaticon-quote d-block ml-auto quote"></span>
                                        </p>
                                        <h4>Gregory Lowery</h4>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-single-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="testimonial-image-wrapper">
                                    <div class="tastimonial-image">
                                        <img src={require('../assets/images/testimonial/testimonial-3.jpg')} alt="bright-side-security-private-security-officers-in-fremont-california" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="testimonial-slider-wrapper">
                                    <div class="testimonial-slid-box">
                                        <p class="mb-25"><span class="flaticon-quote-1 d-block quote mb-25"></span>
                                        I want to express how highly pleased I am with Bright Side Security Service. I recommend everyone to give a call to  them for the best and safe service.<span class="flaticon-quote d-block ml-auto quote"></span>
                                        </p>
                                        <h4>Vennessa May</h4>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-single-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="testimonial-image-wrapper">
                                    <div class="tastimonial-image">
                                        <img src={require('../assets/images/testimonial/testimonial-2.jpg')} alt="bright-side-security-private-security-officers-in-fremont-california" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="testimonial-slider-wrapper">
                                    <div class="testimonial-slid-box">
                                        <p class="mb-25"><span class="flaticon-quote-1 d-block quote mb-25"></span>
                                        We highly appreciate the professionalism and service of Bright Side Security Service. We are customers of them since many years and we enjoy a peace of mind by getting highly secured<span class="flaticon-quote d-block ml-auto quote"></span>
                                        </p>
                                        <h4>Simth Lender</h4>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                        <span class="flaticon-pointed-star"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default OurTestimonialsSection;
