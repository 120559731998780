import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
// import Clients from "./Clients";

function AboutUsSection(props) {
  return (
    <Section className="about-us py-150" id="about">
        <div class="container">
                <div class="row coustom-row">
                    <div class="col-md-6">
                        <div class="section-header mb-15 ">
                            <p class="section-highlight ">about us</p>
                            <h2 class="">who we are</h2>
                        </div>
                        <p class="mb-30 ">
                            We believe that a secure society needs a secure work environment. In addition to deterance and protection, presence of security officers brings peace of mind to employees. We have personal to match your needs and your environment. With many years of experience, we are complement for your safety ...
                        </p>
                        <div class="row coustom-row">
                            <div class="col-md-6">
                                <div class="mission-vision-box">
                                    <h4 class="">Availability</h4>
                                    <p class="">
                                      We have network of officers that are ready and available to work.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mission-vision-box ">
                                    <h4 class="">Call us</h4>
                                    <p class="mb-50 slow">
                                    We are only a phone call away. Call us in the office or on our 24 hour hotline.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="link-btn mb-100 ">
                            <a href="tel:5103961974" class="theme-btn btn-style-two">Call Now : (510) 196-1974</a>
                        </div>
                        <div class="about-us-image ">
                            <img src={require('../assets/images/about-us/about-us-1.jpg')} alt="bright-side-security-private-security-officers-in-fremont-california" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service-image mb-100 ">
                        <img src={require('../assets/images/about-us/about-us-2.jpg')} alt="bright-side-security-private-security-officers-in-fremont-california" />
                        </div>
                        <div class="section-header mb-15">
                            <p class="section-highlight ">service</p>
                            <h2 class="">Service On Demand</h2>
                        </div>
                        <div class="service-content">
                            <p class="">
                            Our officers are trained for different fields and flexible work environment. Let us know for any temporary or permanent position that we can help.
                            </p>
                            <h4 class="mt-30 ">Our Security Policy</h4>
                            <p class="">Our clients ranges from banks and churches to hotels and events. Let us know how we may help you with your security needs.</p>
                        </div>
                        <div class="link-btn mt-50 ">
                            <a href="#contact" class="theme-btn btn-style-two">Get In Touch</a>
                        </div>
                    </div>
                </div>
            </div>
    </Section>
  );
}

export default AboutUsSection;
