import React, { useState } from "react";
import Section from "./Section";
import SliderContactForm from "./SliderContactForm";
import slidercontact from "./../util/slidercontact.js";
import SectionHeader from "./SectionHeader";
import SectionButton from "./SectionButton";
import "./HeroSection.scss";

function HeroSection(props) {
    const [status, setStatus] = useState();

    const onSubmit = ({ name, email, number, service }) => {
        setStatus({ type: "pending" });

        slidercontact.submit({ name, email, number, service }).then(() => {
            setStatus({
                type: "success",
                message: "Your message has been sent! We'll get back to you soon."
            });
        });
    };
  return (
    <Section className="main-slider">
        <ul class="main-slider-carousel">
                <li class="slider-wrapper">
                    <div class="image"><img src={require('../assets/images/slider/slider-1.jpg')} alt="" /></div>
                    <div class="slider-caption">
                        <div class="container">
                            <div class="slider-content">
                                <h6>License NO. PPO 120617</h6>
                                <h1 class="mt-15 mb-40 mr-80">Premium <span>Security</span> Services Trusted By <span>Millions</span></h1>
                                <p class="mb-50">At Bright Side Security, we provide ourselves on fulfilling security services to our valued clients. Our highly experienced private patrol company goes to great levels to provide exceptional security.</p>
                                <div class="link-btn">
                                    <a href="#about" class="theme-btn btn-style-one">Learn More</a>
                                </div>
                            </div>
                            <div class="slider-form bg-white contact-us-form-wrapper">
                                <SliderContactForm
                                    onSubmit={onSubmit}
                                    status={status}
                                />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            {/* <div class="social-icon-box">
                <p class="social">Social</p>
                <div class="social-icon">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                    <a href="#"><i class="fab fa-instagram"></i></a>
                </div>
            </div> */}
    </Section>
  );
}

export default HeroSection;
