import React from "react";

function NavbarContainer(props) {
  return (
    <header class="main-header">
            
            <div class="header-upper">
                <div class="container clearfix">
                  {props.children}
                </div>
            </div>
    </header>
    // <nav
    //   className={
    //     "navbar" +
    //     (props.color ? ` is-${props.color}` : "") +
    //     (props.spaced ? " is-spaced" : "")
    //   }
    // >
    //   {props.children}
    // </nav>
  );
}

export default NavbarContainer;
