import React from "react";
import ReactDom from "react-dom";
import "./styles/bootstrap.css";
import "./styles/animate.min.css";
import "./styles/flaticon.css";
import "./styles/menu.css";
import "./styles/responsive.css"
import "./styles/slick.css"
import "./styles/spacing.min.css"
import "./styles/global.scss";

import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";

ReactDom.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
